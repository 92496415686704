@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css?family=DM+Sans:400,400italic,500,500italic,700,700italic&display=swap");
@import url("https://fonts.googleapis.com/css?family=Quicksand:300,400,700&display=swap");
@import url("./styles/animations.scss");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  color: #486284;
  background: white;
  font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 72px;
  font-weight: 500;
  line-height: 110%;
}

h2 {
  font-size: 64px;
  font-weight: 500;
  line-height: 110%;
}

h3 {
  font-size: 48px;
  font-weight: 500;
  line-height: 110%;
}

h4 {
  font-weight: 600;
  font-size: 48px;
  letter-spacing: -1px;
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

h5 {
  font-weight: 600;
  font-size: 40px;
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

h6 {
  font-weight: 600;
  font-size: 32px;
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.header{
  height: 80vh;
}

@media only screen and (max-width: 768px) {
  .header{
    height: 100vh;
  }
  p {
    font-size: 16px;
  }
  h1 {
    font-size: 52px;
    font-weight: 500;
    line-height: 100%;
  }
  h2 {
    font-size: 50px;
    font-weight: 500;
    line-height: 100%;
  }

  h3 {
    font-size: 40px;
    font-weight: 500;
    line-height: 100%;
  }

  h4 {
    font-weight: 600;
    font-size: 32px;
    letter-spacing: -1px;
    line-height: 100%;
    font-family: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
  }

  h5 {
    font-weight: 600;
    font-size: 28px;
    line-height: 100%;
    font-family: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
  }

  h6 {
    font-weight: 600;
    line-height: 100%;
    font-size: 24px;
    font-family: "Quicksand", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
  }
}

@layer components {
  .gradient-blue {
    @apply bg-gradient-to-tr from-palette-dark-blue to-palette-light-blue;
  }
  .gradient-purple {
    @apply bg-gradient-to-tr from-palette-dark-purple to-palette-light-purple;
  }
  .gradient-orange {
    @apply bg-gradient-to-tr from-palette-dark-orange to-palette-light-orange;
  }
  .font-outline-2 {
    -webkit-text-stroke: 1px white;
  }
  .font-outline {
    -webkit-text-stroke: 1px #4D4D4D;
  }
}
