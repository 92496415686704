.bounce-in {
  animation: bounceIn 800ms ease-in-out;
}

@keyframes bounceIn {
  0% {
    transform: translateY(-12%);
    opacity: 0;
  }

  40% {
    transform: translateY(6%);
  }

  75% {
    transform: translateY(-4%);
    opacity: 100;
  }

  100% {
    transform: translateY(0%);
  }
}

.slide-in {
  animation: slideIn 500ms ease-in-out;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    opacity: 100;
    transform: translateY(0%);
  }
}

.paralax {
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-position: 50% 50%;
}
